@import '../../style/mixins.scss';

.input-phone,
.input-client {
    padding-top: 20px;
    display: flex;

}

.AppMini {
    .input-phone{
       
        align-items: center;
    }
    .input-client {
        align-items: center;
        flex-direction: column;
    }
}
.wrong {
    visibility: hidden;
}


.keyboard_wrapper {
    display: flex;
    justify-content: flex-end;
}

.inputs {
    width: 40%;
    margin: none;
}

.input-keyboard-column {
    width: 60%;

    .keyboard {
        margin-left: 20px;
        margin-right: 20px;
    }

    .title {
        height: 40px;
        margin: auto;
        color: red;
        text-align: center;
        font-size: 24px;
        margin-bottom: 24px;
    }
}
.AppMini{
    .input-keyboard-column {
        width: 100%;
    }
}

.order-input_line {
    @include flex-set(row, space-between, flex-start);
    margin: 0 20px;

    .order-input_wrapper {
        @include flex-set(column);
        width: 410px;
        margin-bottom: 20px;
    }

    .order-input {
        @include font-set(36px, normal, 500);
        width: 100%;
        padding: 10px 0;
        text-align: center;
        border-bottom: 10px solid rgb(243, 243, 243);

        &.focused {
            border-color: $main-color;
        }

        &.error {
            border-color: red;
        }
    }

    .order-input::-webkit-input-placeholder {
        color: $third-color;
    }

    .order-input::-moz-placeholder {
        color: $third-color;
    }

    .order-input:-ms-input-placeholder {
        color: $third-color;
    }

    .order-input::-ms-input-placeholder {
        color: $third-color;
    }

    .order-input::placeholder {
        color: $third-color;
    }

    .order-checkbox_wrapper {
        padding: 10px 0;
        @include flex-set(row, center, center);
        position: relative;

        &:before {
            @include pseudo;
            left: 0;
            @include box(35px);
            border: 4px solid $main-color;
        }
    }

    .order-checkbox {
        @include box(40px);
        opacity: 0;
    }

    .order-checkbox_text {
        @include font-set(18px, normal, 500, $main-color);
        padding: 0 20px;
    }

    .order_warning-line {
        margin: 20px 0;
        @include flex-set(row, center, center);
        position: relative;
    }

    .order_warning-line_heading {
        @include font-set(18px, normal, 500, $main-color);
    }

    .order_warning-line_icon {
        @include pseudo;
        @include flex-set(row, center, center);
        @include box(25px);
        background: url('/assets/img/icons/info.svg') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        right: -40px;
    }


}

.margin_top {
    margin-top: 20px;
    
}

.call-prefer {
    @include flex-set(column, center, center);
    width: 100%;
    margin: auto;

    .prefer_heading {
        @include font-set(24px, normal, 600);
        margin-bottom: 10px;
    }

    .prefer_select {
        @include flex-set(row, center, center);
    }

    .prefer_option {
        background: $fourth-color;
        @include box(60px);
        background: $gray-color;
        @include flex-set(row, center, center);

        margin: 0 10px;
        padding: 10px;
        outline: none;
        -webkit-tap-highlight-color: transparent;

        &.active {
            background: $main-color;
        }

        &.whatsapp {
            &.active {
                background: #66FF33;
            }
        }

        &.telegram {
            &.active {
                background: #3399ff;
            }
        }

        &.sms {
            &.active {
               background: #ffCC00;
            }
        }

        &.phonecall {
            &.active {
                background: #FF0066;
            }
        }
    }

    .option_pic {
        width: 90%;
    }
}