@import '../../style/mixins.scss';
.product-item-list{
    height: 1350px;
    overflow-y: scroll;
}
.item-list{
    margin-top: 30px;
    
    .title{
        margin-left: 30px;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        color: black;
        line-height: normal;
    }
    .container{
        @include flex-set(row,flex-start,center);
        flex-wrap: wrap;
    }
    .item-cards{
        @include flex-set(row,flex-start,center);
        flex-wrap: wrap;
    }
    .item-card{
        background: $fourth-color;
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 20px;
        margin: 20px 13px;
        position: relative;
        @include flex-set(column);
        @include box(320px,400px);
        // &:after{
        //     @include pseudo;
        //     @include box(50px);
        //     @include cover-background;
        //     background: url('/assets/img/icons/forward-button.svg');
        //     top: 20px;
        //     right: 30px;
        // }
        &.salecard{
            .crossSale{
                display: none
            }
        }
    }
    .next { //FOR CATEGORY
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 50%;
    
        }
    }
    .item-card_title{
        @include font-set(20px,normal,500);
        //padding: 30px 0;
        width: 98%;
        white-space:pre-wrap;
        overflow: hidden
    }
    
    .item-card_pic{
        @include box(100%,200px);
        padding-top: 10px;
        -o-object-fit: contain;
           object-fit: contain;
    }
    .item-card_deposit{
        font-size: 64px;
        color: red;
        font-size: 64px;
    color: red;
    position: absolute;
    top: -50px;
    }
    .item-card_price{
        @include font-set(40px,normal,900);
        text-align: center;
        padding: 20px 30px;
        position: absolute;
        bottom: 30px;
        left: 0;
        &:after{
            @include pseudo;
            @include box(180px,4px);
            background: $main-color;
            bottom: 0;
            left: 0;
            border-bottom: 12px solid $main-color;
        }
    }
    &.small{
        .item-card{
            @include box(320px,350px);
        }
        .item-card_pic{
            @include box(100%,160px);
        }
    }
    
}
