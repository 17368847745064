@import '../../style/mixins.scss';



.keyboard {
    background: black;
    padding:5px;
    width: 570px;
    
    .keyboard_line {
        @include flex-set(row, center, center);
        width: 100%;
       
        &.right {
            justify-content: flex-end;
        }

        .keyboard_button {
            background: #2D2D2D;
            margin: 5px;
            @include box(42px);
            @include flex-set(row, center, center);
            @include font-set(20px, normal, 700, white);
            
            text-align: center;
            
            &.large{
                flex: 2 1 0;
            }
         

            &.backspace {
                width: 90px;
                img{
                    padding: 10px;
                }
               
            }
            &.long {
                
                width: 90px;
            }
            
            &.black {
                color: $main-color;
                color: white;
            }
            &.texted{
                font-size: 16px;
                padding: 20px;
                color: $main-color;
            }
          
            &.primary{
                color: $main-color;
            }
            &.lang{
                width: 60px;
                color: white;
                background-color: $main-color;
            }
            &.enter{
                width: 120px;
            }
            &.space {
                flex: 5 1 0;
                
                color: $main-color;
                display: flex;
                justify-content: center;
                align-items: end;

                .line{
                    margin-bottom: 10px;
                    height: 2px;
                    width: 90%;
                    background-color: $main-color;
                }
            }
        }

        .keyboard_button_pic {
            @include box(50px);
            -o-object-fit: contain;
            object-fit: contain;
        }

        &.numbers {
           
        }

        .main-button {
            margin: 5px;
        }
       
    }
    .keyboard_line_around {
        justify-content: space-between;
    }
    

}
.AppMini{
    .keyboard {
        width: 1080px;
        .keyboard_button{
            @include box(84px);
        }
    }
   
}