.emulator {
    display: flex;
    justify-content: flex-start;
}

.screen {
    // width={768 / 2} height={1366 / 2}

    width: 0px;

    height: 0px;
    left: -300px;
    top: -600px;
    background: blue;
position: absolute;
    
}

// iframe {
// background-color: white;
//     transform: scale(0.3);
//     width: 1080px;

//     height: 1920px;
// }

.box{
    background-color: red;
    width: 100%;
    height: 100vh;
    display: flex;
    .box-left{
        background-color: blue;
      
        width: 45%;
        height:100%;
        width: 45%;
        flex-direction: column;
        justify-content: space-around;
        display: flex;
        .box-row{
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;
            .box-cell{
                width: 100%;
                margin: 10px;
                height: 100%;
                background: palevioletred;
            }   
        }
    }
    .box-center{
        background-color: yellow;
        height:100%;
        width: 10%;
    }
    .box-right{
        background-color: green;
        height:100%;
        width: 45%;
        flex-direction: column;
        justify-content: space-around;
        display: flex;
        .box-row{
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;
            .box-cell{
                width: 100%;
                margin: 10px;
                height: 100%;
                background: palevioletred;
            }   
        }
    }

}