@import '../../style/mixins.scss';

// .homepage-videos_wrapper{
//     @include flex-set(row,center,center);
//     @include box(100%);
//     .homepage-videos{
//         box-shadow: 0 0 10px 10px rgba(0,0,0,0.5);
//         background: white;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//         z-index: 20;
//         @include box(80%, 800px);
//         @include flex-set(column,center,center);
//     }
//     .recommended-videos{
//         width: 80%;
//         @include flex-set(row,space-between);
//     }
//     .recommended-video{
//         @include box(220px);
//         img{
//             @include box(100%);
//         }
//     }
// }

.category-card {
    text-align: center;

    .item-card-price-block {
        width: 100%;
        .item-card_price_sale {
            display: none;
        }

        .crossSale {
            display: none;
        }

        &.sale {

            // position: relative;
            .item-card_price_sale {
                font-size: 45px;
                color: red;
                position: absolute;
                bottom: 90px;
                display:block;
            }

            .crossSale {
                position: absolute;
                width: 50%;
                bottom: 50px;
                display:block;
            }

        }
    }

}

.buy-here{
    
    font-weight: 600;
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 56px;
    background: linear-gradient(top bottom,#E1E2E5,#FFFFFF);
    .title{
        font-size: 40px;
        color: $main-color2
    }
    .subtitle{
        font-size: 20px;
        margin-top: 20px;
        font-weight: 300;

    }

}
.homepage-counters{
    height: 150px;
    display: flex;
    margin-right: 20px;
    margin-left: 20px;
    justify-content: space-around;
    background: linear-gradient(to right, #E8EAEC, #FFFFFF);
    .stat_image{

    }
    .counter{
        padding-top: 0px;
       
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     
        .counter-count{
            
            font-size: 48px;
            color:$main-color ;
            font-weight: 600;
        }
        .counter-title{
            margin-top: 4px;
            font-size: 32px;
            font-weight: 300;
            color:black ;
        }
    }
}

.homepage-action{
    height: 243px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(to right, #E8EAEC, #FFFFFF);
    .stat_image{

    }
    .action-item{
        padding-top: 0px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        img{
            height: 100%;
        }
        .action-title{
            margin-top: 4px;
            font-size: 32px;
            font-weight: 300;
            color:black ;
            span {
                color: red;
            }
        }
        .action-subtitle{
            margin-top: 4px;
            font-size: 32px;
            font-weight: 300;
            color:black ;
        }
    }
}
.border-test{
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 10px;
    background: linear-gradient(to bottom, #E8EAEC, #FFFFFF);
}
.categories-list{
    margin-top: 0px !important;
    
    .title{
        margin-left: 30px;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        color: black;
        line-height: normal;
    }
    .container{
        @include flex-set(row,flex-start,center);
        flex-wrap: wrap;
    }
    .item-cards{
        @include flex-set(row,flex-start,center);
        flex-wrap: wrap;
    }
    .item-card{
        background: linear-gradient(to top , #FD9C2833, #FFFFFF 100px);
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 20px;
        margin: 20px 13px;
        position: relative;
        @include flex-set(column);
        @include box(310px,310px);
        border-width: 4px;
        border-style: solid;
        border-radius: 5%;
        border-color: $main-color;
      
        &.salecard{
            .crossSale{
                display: none
            }
        }
    }
    .next { //FOR CATEGORY
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 50%;
    
        }
    }
    .item-card_title{
        @include font-set(20px,normal,500);
        //padding: 30px 0;
        width: 98%;
        white-space:pre-wrap;
        overflow: hidden
    }
    
    .item-card_pic{
        @include box(100%,200px);
        -o-object-fit: contain;
           object-fit: contain;
    }
    .item-card_deposit{
        font-size: 64px;
        color: red;
        font-size: 64px;
    color: red;
    position: absolute;
    top: -50px;
    }
    .item-card_price{
        @include font-set(40px,normal,200);
        text-align: center;
        width: 100%;
        color: $main-color2;
        .item-card_symbol{
            font-weight: 600;
        }
    }
   
    &.small{
        .item-card{
            @include box(320px,350px);
        }
        .item-card_pic{
            @include box(100%,160px);
        }
    }
}
.homepage-buttons {
    padding: 20px 0;
    padding-top: 20px;
    .container {
        @include flex-set(row, space-between, center);
    }

    .homepage-button {
        @include flex-set(column,center,flex-start);
        background: $main-color2;
        text-align: left;
        padding: 30px 40px;
        @include box(49%, 180px);
        position: relative;
        border-radius: 10px;
        box-shadow: 0px 4px 10px 0px #00000080;

        
    }
    .first{
        &:after {
            @include pseudo;
            @include box(80px, 140px);
            right: 20px;
            top: 20px;
            bottom: 20px;
            background: url('/assets/img/icons/phone_home_brocken.png');
            @include cover-background;
        }
    }
    
    .second{
        &:after {
            @include pseudo;
            @include box(80px, 140px);
            right: 20px;
            top: 20px;
            bottom: 20px;
            background: url('/assets/img/icons/phone_home.png');
            @include cover-background;
        }
    }

    .homepage-button_text {
        @include font-set(40px, normal, 700);
        font-weight: 300;
        text-align: center;
        width: 80%;

    }
}

.hidden {
    display: none;
}