@import '../../style/mixins.scss';

footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .footer-phone{
        @include flex-set(row,flex-start,center);
        padding: 30px 25px;
    }
    .footer-phone_icon{
        width: 50px;
    }
    .footer-phone_text{
        padding: 0 20px;
        @include font-set(32px,normal,700,$third-color);
        font-weight: 600;
    }
    .version {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}