@import "../../style/mixins.scss";



.pay-block {
    margin: 25px;
    margin-left: 0px;
    font-weight: 700;
    font-size: 32px;

    .pay-options {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .pay-option {
            // width: 200px;
            height: 120px;
            border-style: solid;
            @include flex-set(row, center, center);
            padding-left: 30px;
            padding-right: 30px;
            background-color: #ff782a;
            img{
                margin-left: 20px;
            }
        }

        .sbp {}

        .card {
            // margin-left: 10px;
        }
    }

}
.product-pay {
    .pay-block {
        margin-left: 0px;
       
    }
}
