@import '../../style/mixins.scss';

.repair-options{
    
    padding: 10px 10px;
    margin-top: 30px;
    @include flex-set(row,space-around,center);
    width: 100%;
    .cheper{
        background: #01CC00;
    }
    .faster{
        background: #3398FD;
    }
    
    .free{
        background: $main-color;
    }
    
}
.repair-option{
    padding: 50px 0;
    margin: 10px;
    width: 100%;
    height: 740px;
    position: relative;
    max-width: 50%;
}

.repair_option_list {
    color: white;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .repair_option_item{
        width: 90%;
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .repair_option_item_price{
            font-size: 64px;
            font-weight: 600;
        }
        .repair_option_item_text{
            font-size: 32px
        }
    }
}

.notion{
    position: absolute;
    bottom: 50px;
    color: white;
    width: 100%;
    text-align: center;
    font-size: 24px;

}
.repair-option_heading{
    margin-top: 20px;
    @include font-set(72px,normal,500);
    text-align: center;
   
    color: white;
}
.repair-option_heading2{
    margin-top: 25px;
    @include font-set(30px,normal,500);
    text-align: center;
   
    color: white;
    .bigger{
       font-size: 35px;
    }
}
.repair-option_heading3{
    margin-top: 48px;
    @include font-set(20px,normal,500);
    text-align: center;
   
    color: white;
}
.free{
    .repair_option_list{
        margin-top: 90px;
    }
    .repair-option_heading{
        margin-top: 0;
    }
}
.repair-option_list{
    @include flex-set(column);
    margin-top: 20px;
    padding: 0px 45px;
}
.repair-option_line{
    @include font-set(24px,normal,700);
   padding: 18px 0 18px 44px;
   position: relative;
   i{
       font-size: 34px;
       font-weight: 900;
   }
   &:before{
       @include pseudo;
       background: url('/assets/img/icons/mark.png') no-repeat;
       background-position: center center;
       left: -10px;
       top: 50%;
       transform: translateY(-50%);
       width: 40px;
       height: 30px;
   }
}
.icon_contianer{
    display: flex;
    justify-content: center;
    img{
        width: 150px;
        height: 150px;
    }
    .repair-option_icon{
       
        margin: auto;
        // position: absolute;
        // bottom: 44px;
        // // left: 50%;
        // transform: translateX(-50%);
    }
}

.repair_back-button{
    margin-top: 30px;
    @include flex-set(row,center,center);
}
