@import '../../style/mixins.scss';

.commentary{
    @include flex-set(row,center,center);
    resize: none;
    .commentary_text{
        @include box(940px,390px);
        @include font-set(32px,normal,500);
        word-wrap: break-word;
        word-break: break-all;
        background: rgb(243,243,243);
        overflow-y: scroll;
        padding: 30px;
        &::-webkit-scrollbar {
            display: none;
        }
        &.placeholdered{
            @include flex-set(column,center,center);
            text-align: center;
            color: rgb(180,180,180);
        }
    }
}
.enter_comment_layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .comment_container{
        width: 45%;
        display: flex;
        justify-content: center;
        .commentary{
            width: 90%;
        }
        
    }
    .keyboard_container{
        height: 100%;
        width: 55%;
        display: flex;
        justify-content: end;
        padding: 20px;
    }
}

.AppMini{
    .enter_comment_layout{
        flex-direction: column;
        .comment_container{
            width: 90%;
            
            
        }
        .keyboard_container{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: end;
            padding: 20px;
        }
    }
}