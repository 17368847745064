@import '../../style/mixins.scss';


.wait-receipt-wrapper{
    @include flex-set(row,center,center);
    .wait-dialog{
        width: 950px;
        height: 600px;
        @include flex-set(column,center,center);
        position: absolute;
        background: white;
        z-index: 20;
        box-shadow: 0 0 10px 10px rgba(0,0,0,0.5);
        padding: 20px;
        top: 35%;
        left: 50%;  
        transform: translate(-50%,-50%);  
    }
    .wait-dialog-title{
        @include flex-set(column,center,center);
        font-size: 40px;
    }
    .wait-dialog-content{
        @include flex-set(column,center,center);
        margin: 30px;
        img{
            width: 250px
        }
    }
    .wait-dialog-footer{
        @include flex-set(column,center,center);
        font-size: 40px;
    }
  }
