@import '../../style/mixins.scss';

.card-methods_wrapper {
    @include flex-set(row, center, center);

    .saled-window {
        width: 90%;

        @include flex-set(row, space-around, center);
        position: absolute;
        background: white;
        z-index: 20;
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
        padding: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);

        .saled-value {
            font-size: 200px;
            margin-top: 70px;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;


        .saled-title {
            font-size: 40px;
        }

        .sale-percent {
            font-size: 200px;
            margin-top: 70px;
            font-weight: 600;
            color: $main-color;
        }

        .saled-buttons {
            display: flex;

            align-items: center;
            justify-content: space-between
        }


        .pinpad-image {
            @include flex-set(row, center, center);

        }
    }

    .methods_heading {
        @include font-set(32px, normal, 600);
        margin-bottom: 30px;
    }

    .methods {
        @include flex-set(row, space-between, center);
        padding: 0 40px;
        width: 100%;
        margin-bottom: 40px;
    }

    .method {
        width: 40%;
    }

    .method_pic {
        width: 100%;
    }

    &.hidden {
        display: none
    }

    .method-description {
        @include flex-set(column, center, center);
        border: 10px solid $main-color;
        border-radius: 24px;
        margin: 40px 0;
        padding: 20px;

        &.hidden {
            display: none;
        }
    }

    .method-description-text {
        @include font-set(40px, normal, 700);
        padding: 20px 0;
        text-align: center;
    }

    .method-description-pic {
        width: 400px;
    }

    $primary-color: $main-color;
    $secondary-color: #333333;
    $highlight-color: #d9534f;
    $background-color: #f5f5f5;
    $container-bg-color: #ffffff;
    $shadow-color: rgba(0, 0, 0, 0.1);
    .promo_container{
        width: 90%;
    }
    .promo_container h1 {
        color: $primary-color;
        font-size: 75px;
        text-align: center;
        margin-bottom: 20px;
    }
    .promo_container h2 {
        color: $primary-color;
        font-size: 40px;
        text-align: center;
        margin-bottom: 20px;
    }

    .promo_container p {
        color: $secondary-color;
        font-size: 18px;
        line-height: 1.5;
    }

    .promo_container .highlight {
        font-weight: bold;
        color: $highlight-color;
    }
    .promo_container ul {
        list-style: none;
        padding: 0;
        width: 70%;
        list-style-type: "🔸";
    }
    .promo_container .features-list{
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .promo_container li {
        margin-top: 20px;
        font-size: 30px;
        padding-left: 20px;
    }

    .promo_container .emoji {
        font-size: 24px;
        margin-right: 10px;
    }
    .expire_date{
        margin-top: 40px;
    }
}