@import '../../style/mixins.scss';

.numboard {
    background: black;
    padding:5px;
  
    .numboard_line {

        @include flex-set(row, center, center);
        width: 100%;
       
        &.right {
            justify-content: flex-end;
        }

        .numboard_button {
            background: #2D2D2D;
            margin: 5px;
            width: 120px;
            height: 90px;
            
            @include flex-set(row, center, center);
            @include font-set(40px, normal, 700, white);
            text-align: center;
            
          
            &.texted{
                font-size: 16px;
                padding: 20px;
                color: $main-color;
            }
          
            &.primary{
                color: $main-color;
            }
            &.lang{
                width: 60px;
                color: white;
                background-color: $main-color;
            }
            &.enter{
                width: 120px;
            }
            &.space {
                flex: 5 1 0;
                
                color: $main-color;
                display: flex;
                justify-content: center;
                align-items: end;

                .line{
                    margin-bottom: 10px;
                    height: 2px;
                    width: 90%;
                    background-color: $main-color;
                }
            }
        }

        .numboard_button_pic {
            // @include box(50px);
            -o-object-fit: contain;
            object-fit: contain;
            width: 60px;
            height: 40px;
        }

        &.numbers {
           
        }

        .main-button {
            margin: 5px;
        }
       
    }
    .numboard_line_around {
        justify-content: space-between;
    }
    

}
