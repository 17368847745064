@import '../../style/mixins.scss';
.item-info{
    @include flex-set(row,space-between);
    padding-top: 40px;
    min-height: 850px;
    margin-left: 20px;
    margin-right: 20px;
    .item-info_half{
       
        width: 47%;
        @include flex-set(column);
        &.info{
            height: 800px;
            justify-content: space-between;
        }
    }
    .item-info_pic{
        width: 100%;
        margin-bottom: 20px;
    }
    .item-info_heading{
        @include font-set(42px,normal,700);
    }
    .item-info_description{
        margin: 20px 0;
        white-space: pre-wrap;
        @include font-set(22px,normal,500);
    }
    .item-info_price-sale{
        position: absolute;
        @include font-set(60px,normal,900);
        color: red;
        bottom: 60px;
        display: none;
    }
    .crossSale{
        position: absolute;
        bottom: 30px;
        display: none

    }
    .item-info_price-block{
        position: relative;
        &.sale{
            .item-info_price-sale{
                display: block;
            }
            .crossSale{
                display: block
        
            }
        }
    }
    
    .item-info_price{
        @include font-set(40px,normal,900);
        
        padding: 20px 30px;
        
        position: relative;
        &:after{
            @include pseudo;
            @include box(180px,4px);
            background: $main-color;
            bottom: 0;
            left: 0;
            border-bottom: 12px solid $main-color;
        }
    }
    .item-info-other-pics{
        width: 100%;
        @include flex-set(row,center,center);
        flex-wrap: wrap;
    }
    .item-info-other-pic{
        @include box(130px);
        object-fit: cover;
        margin: 10px;
    }
    .main-button{
        margin-top: 30px;
       img{
        width: 100px;
        padding: 10px;
       }
    }
    .pay-card{
        color: black;
    }
    .main-button-icons{
        display: flex;
        justify-content: space-around;
    }
    .pay-sbp{
        border: 5px;
        border-color: black;
        border-style: solid;
        background-color: white;
        color: black;
    }
    .sdb_icon{
        width: 100px;
        
    }
}

.product-info{
    display: flex;
    flex-direction: column;
}
