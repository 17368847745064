.storyVideoDialogBg{
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}
.storyVideoDialog {
  position: absolute;
  width: 55%;
  border-radius: 42px;
  overflow: hidden;
  top: 35%;          /* Перемещаем элемент к середине по вертикали */
  left: 50%;         /* Перемещаем элемент к середине по горизонтали */
  transform: translate(-50%, -50%); /* Сдвигаем элемент назад на половину его ширины и высоты */
  padding: 20px;
  display: flex;
  justify-content: center;
  .videoProgressBar{
   position: absolute;
   margin-top: 20px;
    width: 95%;
    display: flex;
    justify-content: space-between;
    .videoItemProgressBar{
      margin-right: 4%;
      margin-left: 4%;
      border-radius: 2px;
      width: 100%;
      .videoItemProgressBarContent{
        height: 4px;
        
        background: white;
        -webkit-transition: width 1s linear;
        -moz-transition: width 1s linear;
        -o-transition: width 1s linear;
        transition: width 1s linear;
      }
      background-color: darkgray;
    }
  }
}

.storyVideo {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
  border-radius: 22px;
}

.storyVideo::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.close_icon {
  position: absolute;
  width: 60px;
  height: 60px;
  margin: 30px;
  margin-top: 50px;
  top: 20px;
  right:  20px;
  border-radius: 100%;
  background-color: gray;
  padding: 10px;
}
.stories-header{
  display: flex;
  align-items: center;
  position: absolute;
  margin: 20px;
  margin-top: 40px;
  top: 40px;
  left:  20px;
}
.stories-name{
  margin-left: 20px;
  font-size: 32px;
  font-weight: 600px;
  color: white;
}
.stories-avatar {
 
  border-radius: 100%;
  padding: 4px;
  border-width: 4px;
  border-color: white;
  border-style: solid;
  background: white;
  width: 80px;
  height: 80px;
  img{
    
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}