@import '../../style/mixins.scss';

.order-result-wrapper {
    @include flex-set(column, center, center);


    .order-result {
        border-radius: 24px;
        width: 90%;
        @include flex-set(column, center, flex-start);

        .order-number {
            color: $main-color;
            font-size: 48px;
        }
    }
    .order-result-heading {
        @include font-set(48px, normal, 700);
    }
    .headers-container {
      
    
        .order-result-number {
    
            @include font-set(46px, normal, 500);
            text-align: center;
            margin: 30px 0;
            color: $main-color;
    
            b {
                font-weight: 700;
            }
        }
    }
   

    .order-result-description {
        @include font-set(36px, normal, 500);

        margin-bottom: 60px;
    }

    .getproduct {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;

        .column {
            width: 50%;
            position: relative;
        }
    }

    .arrow_container {
        background-color: orange;
        position: relative;
    }

    @keyframes bouncing {
        0% {
            bottom: 0;
        }

        50% {
            bottom: 20px;
        }

        100% {
            bottom: 0;
        }
    }

    $base: 3rem;

    .arrow-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
    }

    .chevron {
        margin-top: 300px;
        position: absolute;
        width: $base * 3.5;
        height: $base * 0.8;
        opacity: 0;
        transform: scale(0.3);
        animation: move-chevron 3s ease-out infinite;
    }

    .chevron:first-child {
        animation: move-chevron 3s ease-out 1s infinite;
    }

    .chevron:nth-child(2) {
        animation: move-chevron 3s ease-out 2s infinite;
    }

    .chevron:before,
    .chevron:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 50%;
        background: $main-color;
    }

    .chevron:before {
        left: 0;
        transform: skewY(30deg);
    }

    .chevron:after {
        right: 0;
        width: 50%;
        transform: skewY(-30deg);
    }

    @keyframes move-chevron {
        25% {
            opacity: 1;
        }

        33.3% {
            opacity: 1;
            transform: translateY($base * 3.8);
        }

        66.6% {
            opacity: 1;
            transform: translateY($base * 5.2);
        }

        100% {
            opacity: 0;
            transform: translateY($base * 8) scale(0.5);
        }
    }
}