@import './style/reset.scss';
@import './style/mixins.scss';
// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
.main-layout{
    // display: flex;
    // flex-direction: column;
    // justify-content: stretch;
}

.AppMini{
    .header_logo_text{
        font-size: 68px;
    }
    .homepage-buttons .homepage-button_text{
        font-size: 45px;
    }
    .top_line .top_line_text{
        font-size: 30px;
    }
    .support_line {
    
        font-size: 30px;
    }
    .item-list .container {
        justify-content: center;
    }
}