@import '../../style/mixins.scss';

.header-stories-container {
  height: 265px;
  display: flex;
  align-items: center;
  overflow: hidden;
  .header-stories {
    display: flex;
    overflow-x: auto;
    width: 100%;
    padding: 10px;
    overflow: hidden;

    box-sizing: border-box;
    display: flex;
    // justify-content: space-between;
    justify-content: space-around;

    // align-items: center;
    .story-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .story-image-wrapper {
        position: relative;
       
        $rotatespeed: 10s;
        // margin-bottom: 5px;
        .story-border {
          background: url('/assets/img/border.svg');
          background-repeat: no-repeat;
          animation: rotate-border $rotatespeed linear infinite; 
          background-size: cover;
          padding: 15px;
          width: 150px;
          height: 150px;
          .story-image {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: rotate-image $rotatespeed linear infinite; 
          }
        }

        @keyframes rotate-background {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
        @keyframes rotate-image {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(-360deg);
          }
        }

      }

      .story-name {
        margin-top: 18px;
        font-size: 20px;
        color: black;
        text-align: center;
      }
    }
  }

  @keyframes rotate-border {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

}