@import '../../style/mixins.scss';
.support_line{
    background-color: white;
    position: fixed;
    background-color: white;
    padding-bottom: 40px;
    padding-top: 10px;
    bottom: 0;
    width: 100%;
    font-size: 32px;
    font-weight: 300;
    color: gray;
    display: flex;
    justify-content: center;
    .support_phone{
    color: $main-color2;
    font-weight: 600;
    }
}
